import React, { useState, useEffect } from 'react';
import { Paper, Typography, TextField, Checkbox, FormControlLabel, Button, Box, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { api } from '../services/api';

interface NotificationPreferences {
  immediate: boolean;
  weekly: boolean;
}

const UserSettings = () => {
  const [email, setEmail] = useState('');
  const [notificationPreferences, setNotificationPreferences] = useState<NotificationPreferences>({
    immediate: false,
    weekly: false
  });
  const [username, setUsername] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const savedUser = localStorage.getItem('selectedUser');
    if (savedUser) {
      setUsername(savedUser);
      fetchUserSettings(savedUser);
    }
  }, []);

  const fetchUserSettings = async (user: string) => {
    try {
      const settings = await api.getUserSettings(user);
      setEmail(settings.email);
      setNotificationPreferences(settings.notificationPreferences);
    } catch (error) {
      console.error('Error fetching user settings:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await api.updateUserSettings(username, email, notificationPreferences);
      alert('Asetukset päivitetty onnistuneesti!');
    } catch (error) {
      console.error('Error updating user settings:', error);
      alert('Virhe päivitettäessä asetuksia. Yritä uudelleen.');
    }
  };

  const handlePreferenceChange = (preference: keyof NotificationPreferences) => {
    setNotificationPreferences(prev => ({
      ...prev,
      [preference]: !prev[preference]
    }));
  };

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 400, margin: 'auto', mt: 4 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Käyttäjäasetukset
      </Typography>
      <Typography 
        variant="caption" 
        component="div" 
        sx={{ 
          fontFamily: 'monospace', 
          color: 'gray', 
          mt: -1, 
          mb: 2 
        }}
      >
        P-APPI (v__APP_VERSION__)
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <TextField
            fullWidth
            label="Sähköposti"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            inputProps={{
              'aria-label': 'Sähköpostiosoite',
              'aria-required': 'true',
            }}
            required
          />
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle1" gutterBottom>
            Ilmoitusasetukset
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={notificationPreferences.immediate}
                onChange={() => handlePreferenceChange('immediate')}
              />
            }
            label="Välittömät ilmoitukset"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={notificationPreferences.weekly}
                onChange={() => handlePreferenceChange('weekly')}
              />
            }
            label={
              <span>
                Viikoittaiset yhteenvedot
                <Typography variant="caption" display="block">
                  (Lähetetään joka sunnuntai-ilta klo 21:00)
                </Typography>
              </span>
            }
          />
        </Box>
        <Box display="flex" justifyContent="space-between" mt={3}>
          <Button
            onClick={() => navigate('/')}
            sx={{ 
              color: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.light,
              }
            }}
          >
            TAKAISIN KALENTERIIN
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{ 
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
              }
            }}
          >
            PÄIVITÄ ASETUKSET
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default UserSettings;
