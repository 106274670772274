import React, { useState, useEffect } from 'react';
import { Typography, FormControl, InputLabel, Select, MenuItem, Paper } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import PasswordDialog from './PasswordDialog';

interface UserSelectorProps {
  onUserSelect: (user: string) => void;
}

const userPasswords: { [key: string]: string } = {
  'Elmer': 'CPM-644',
  'Ode': 'MZK-157',
};

const UserSelector: React.FC<UserSelectorProps> = ({ onUserSelect }) => {
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const users = ['Ode', 'Elmer'];

  useEffect(() => {
    const savedUser = localStorage.getItem('selectedUser');
    if (savedUser) {
      setSelectedUser(savedUser);
      onUserSelect(savedUser);
    }
  }, [onUserSelect]);

  const handleUserChange = (event: SelectChangeEvent) => {
    const user = event.target.value as string;
    setSelectedUser(user);
    setPasswordDialogOpen(true);
  };

  const handlePasswordSubmit = (password: string) => {
    if (password === userPasswords[selectedUser]) {
      localStorage.setItem('selectedUser', selectedUser);
      onUserSelect(selectedUser);
    } else {
      alert('Väärä rekisteritunnus. Yritä uudelleen.');
      setSelectedUser('');
    }
    setPasswordDialogOpen(false);
  };

  return (
    <Paper elevation={0} sx={{ p: 2, border: '2px solid #000' }}>
      <Typography variant="h6" gutterBottom>
        Valitse käyttäjä
      </Typography>
      <FormControl fullWidth margin="normal" variant="outlined">
        <InputLabel id="user-select-label">Käyttäjä</InputLabel>
        <Select
          labelId="user-select-label"
          value={selectedUser}
          onChange={handleUserChange}
          label="Käyttäjä"
          sx={{ '& .MuiOutlinedInput-notchedOutline': { borderColor: '#000' }, '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#000' } }}
        >
          {users.map((user) => (
            <MenuItem key={user} value={user}>
              {user}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <PasswordDialog
        open={passwordDialogOpen}
        onClose={() => {
          setPasswordDialogOpen(false);
          setSelectedUser('');
        }}
        onSubmit={handlePasswordSubmit}
        username={selectedUser}
      />
    </Paper>
  );
};

export default UserSelector;