import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box } from '@mui/material';

interface DemandChangeDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (reason: string) => void;
}

const DemandChangeDialog: React.FC<DemandChangeDialogProps> = ({ open, onClose, onSubmit }) => {
  const [reason, setReason] = useState('');

  // Reference to hold the battle sound
  const battleSoundRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    // Initialize the Audio object once when the component mounts
    battleSoundRef.current = new Audio('/battle-sound.mp3'); 
    battleSoundRef.current.preload = 'auto'; // Optional: Preload for better performance

    // Cleanup on component unmount
    return () => {
      if (battleSoundRef.current) {
        battleSoundRef.current.pause();
        battleSoundRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (open && battleSoundRef.current) {
      // Play the battle sound when the dialog opens
      battleSoundRef.current.currentTime = 0; // Reset to start
      battleSoundRef.current.play().catch((error) => {
        console.error('Error playing battle sound:', error);
      });
    }
  }, [open]);

  const handleSubmit = () => {
    onSubmit(reason);
    setReason('');
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Pyydä muutosta</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Syy muutokselle"
          type="text"
          fullWidth
          variant="outlined"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', px: 2, pb: 2 }}>
          <Button 
            onClick={onClose}
            variant="outlined"
            sx={{ width: '48%', minHeight: '48px' }}
          >
            Peruuta
          </Button>
          <Button 
            onClick={handleSubmit} 
            disabled={!reason}
            variant="contained"
            sx={{ width: '48%', minHeight: '48px' }}
          >
            Lähetä
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DemandChangeDialog;