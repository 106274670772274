import React, { useCallback, useEffect } from 'react';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Typography, Box, useTheme } from '@mui/material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { isSameDay, isWeekend, isBefore, startOfDay, addMonths, startOfMonth, endOfMonth, isAfter } from 'date-fns';
import { fi } from 'date-fns/locale';
import { Reservation } from '../services/api';

interface CalendarProps {
  reservations: Reservation[];
  onDateSelect: (date: Date | null) => void;
  selectedDate: Date | null;
}

const userColors: { [key: string]: string } = {
  'Ode': '#2ecc71',  // Console green
  'Elmer': '#f39c12', // Retro orange
};

const Calendar: React.FC<CalendarProps> = ({ reservations, onDateSelect, selectedDate }) => {
  const startDate = new Date(2024, 6, 1); // July 1, 2024
  const currentDate = new Date();
  const maxDate = endOfMonth(addMonths(startOfMonth(currentDate), 12));

  const theme = useTheme();
  
  useEffect(() => {
    console.log('Calendar received new reservations:', reservations);
  }, [reservations]);

  const isDateReserved = useCallback((date: Date) => {
    return reservations.some(r => {
      if (r && r.date) {
        return isSameDay(date, new Date(r.date));
      }
      return false;
    });
  }, [reservations]);

  const getReservationColor = useCallback((date: Date) => {
    const reservation = reservations.find(r => r && r.date && isSameDay(date, new Date(r.date)));
    return reservation ? userColors[reservation.user] : undefined;
  }, [reservations]);

  const isDateDisputed = useCallback((date: Date) => {
    const reservation = reservations.find(r => r && r.date && isSameDay(date, new Date(r.date)));
    return reservation ? reservation.demandChange : false;
  }, [reservations]);

  const CustomPickersDay = React.memo((props: PickersDayProps<Date>) => {
    const { day, ...other } = props;
    const isReserved = isDateReserved(day);
    const reservationColor = getReservationColor(day);
    const isDisputed = isDateDisputed(day);
    const isWeekendDay = isWeekend(day);
    const isSelected = selectedDate ? isSameDay(day, selectedDate) : false;
    const isPastDate = isBefore(day, startOfDay(new Date()));
    const isDisabled = isPastDate || isWeekendDay;

    return (
      <Box
        sx={{
          position: 'relative',
          width: 36,
          height: 36,
          margin: '0 2px',
        }}
      >
        <PickersDay 
          {...other} 
          day={day}
          selected={isSelected}
          disabled={isDisabled}
          sx={{ 
            backgroundColor: isReserved ? reservationColor : 'transparent',
            border: isSelected ? `2px solid ${theme.palette.primary.main}` : isReserved ? `2px solid ${reservationColor}` : 'none',
            color: isDisabled ? theme.palette.text.disabled : (isReserved || isSelected ? theme.palette.text.primary : 'inherit'),
            '&:not(.Mui-selected)': {
              color: isDisabled ? theme.palette.text.disabled : 'inherit',
            },
            '&:hover': {
              backgroundColor: isDisabled ? 'transparent' : (isReserved ? reservationColor : theme.palette.action.hover),
            },
            '&.Mui-selected': {
              backgroundColor: isReserved ? reservationColor : theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
            '&.Mui-disabled': {
              color: theme.palette.text.disabled,
              opacity: 0.6,
            },
          }} 
        />
        {isDisputed && (
          <img
            src="/battle.png"
            alt="Disputed"
            style={{
              position: 'absolute',
              top: -5,
              right: -5,
              width: 16,
              height: 16,
            }}
          />
        )}
      </Box>
    );
  });

  const handleDateChange = (date: Date | null) => {
    if (date && 
        !isBefore(date, startDate) && 
        !isAfter(date, maxDate) && 
        !isWeekend(date)) {
      onDateSelect(date);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fi}>
      <DateCalendar
          value={selectedDate}
          onChange={handleDateChange}
          minDate={startDate}
          maxDate={maxDate}
          slots={{
          day: CustomPickersDay,
        }}
        sx={{
          '& .MuiPickersCalendarHeader-root': {
            color: theme.palette.text.primary,
          },
          '& .MuiPickersCalendarHeader-label': {
            color: theme.palette.text.primary,
          },
          '& .MuiPickersCalendarHeader-switchViewButton': {
            color: theme.palette.text.primary,
          },
          '& .MuiPickersDay-root': {
            color: theme.palette.text.primary,
            fontWeight: 'bold',
            width: 36,
            height: 36,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
        }}
      />
      <Typography variant="caption" display="block" textAlign="center" mt={2}>
        {Object.entries(userColors).map(([user, color]) => (
          <span key={user} style={{ marginRight: 10 }}>
            <span style={{ backgroundColor: color, width: 20, height: 20, display: 'inline-block', marginRight: 5, border: `1px solid ${theme.palette.divider}` }}></span>
            {user}
          </span>
        ))}
      </Typography>
    </LocalizationProvider>
  );
};

export default React.memo(Calendar, (prevProps, nextProps) => {
  return (
    prevProps.reservations === nextProps.reservations &&
    prevProps.onDateSelect === nextProps.onDateSelect &&
    prevProps.selectedDate === nextProps.selectedDate
  );
});