import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";

// Initialize Sentry
Sentry.init({
  dsn: "https://766279c2cb6e0acc3c1f64d47453ed76@o4507618793422848.ingest.de.sentry.io/4507618802401360",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function handleOffline() {
  console.log('App is offline');
  Sentry.captureMessage('App went offline', 'info');
}

function handleOnline() {
  console.log('App is back online');
  Sentry.captureMessage('App came back online', 'info');
}

window.addEventListener('offline', handleOffline);
window.addEventListener('online', handleOnline);

// Helper function to check if the error is an Error object
const isError = (error: unknown): error is Error => {
  return error instanceof Error;
};

// Define ErrorFallback component before using it
const ErrorFallback: Sentry.FallbackRender = ({ error }) => {
  React.useEffect(() => {
    if (isError(error)) {
      Sentry.captureException(error);
    } else {
      Sentry.captureMessage('An unknown error occurred');
    }
  }, [error]);

  return (
    <div>
      <h1>Oops! Something went wrong.</h1>
      <p>Error: {isError(error) ? error.message : String(error)}</p>
    </div>
  );
};

// Wrap your app with Sentry's profiler
const SentryApp = Sentry.withProfiler(App);

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <SentryApp />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);

serviceWorkerRegistration.register({
  onSuccess: (registration) => {
    console.log('Service Worker registered successfully');
    Sentry.captureMessage('Service Worker registered successfully', 'info');
  },
  onUpdate: (registration) => {
    console.log('New content is available; please refresh.');
    Sentry.captureMessage('New content available', 'info');
  },
});

reportWebVitals(({ id, name, value }) => {
  Sentry.captureMessage(`Web Vital: ${name} - ${value}`, 'info');
});
