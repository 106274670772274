import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';

interface PasswordDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (password: string) => void;
  username: string;
}

const PasswordDialog: React.FC<PasswordDialogProps> = ({ open, onClose, onSubmit, username }) => {
  const [password, setPassword] = useState('');

  useEffect(() => {
    setPassword('');
  }, [open]);

  const handleSubmit = () => {
    onSubmit(password.toUpperCase());
    setPassword('');
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Syötä rekisteritunnus käyttäjälle {username}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Rekisteritunnus"
          type="text"
          fullWidth
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value.toUpperCase())}
          inputProps={{ maxLength: 7 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Peruuta</Button>
        <Button onClick={handleSubmit} disabled={password.length !== 7}>Vahvista</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordDialog;