class WebSocketService {
  private static instance: WebSocketService | null = null;
  private socket: WebSocket | null = null;
  private reconnectAttempts = 0;
  private maxReconnectAttempts = 5;
  private reconnectInterval = 5000; // 5 seconds
  private maxReconnectDelay = 30000; // 30 seconds
  private reconnectTimer: NodeJS.Timeout | null = null;
  private heartbeatInterval: NodeJS.Timeout | null = null;
  private url: string = '';
  private onMessage: ((data: any) => void) | null = null;

  private constructor() {}

  public static getInstance(): WebSocketService {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  public initialize(url: string, onMessage: (data: any) => void): void {
    this.url = url;
    this.onMessage = onMessage;
  }

  public ping(): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({ type: 'ping' }));
    }
  }

  public connect() {
    if (!this.url || !this.onMessage) {
      console.error('WebSocketService not initialized. Call initialize() first.');
      return;
    }
  
    if (this.socket && (this.socket.readyState === WebSocket.OPEN || this.socket.readyState === WebSocket.CONNECTING)) {
      console.log('WebSocket is already connected or connecting');
      return;
    }
  
    // Add a small delay before attempting to connect
    setTimeout(() => {
      this.socket = new WebSocket(this.url);
  
      this.socket.onopen = () => {
        console.log('WebSocket connected');
        this.reconnectAttempts = 0;
        if (this.reconnectTimer) {
          clearTimeout(this.reconnectTimer);
          this.reconnectTimer = null;
        }
        this.startHeartbeat();
      };
  
      this.socket.onmessage = this.handleMessage;
  
      this.socket.onclose = (event) => {
        console.log(`WebSocket disconnected. Code: ${event.code}, Reason: ${event.reason}`);
        this.stopHeartbeat();
        this.reconnect();
      };
  
      this.socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    }, 1000); // 1 second delay
  }

  private handleMessage = (event: MessageEvent) => {
    try {
      const data = JSON.parse(event.data);
      switch (data.type) {
        case 'welcome':
          console.log('Received welcome message:', data.message);
          break;
        case 'newReservation':
        case 'updateReservation':
        case 'deleteReservation':
          // Ensure dates are properly parsed
          if (data.data && data.data.date) {
            data.data.date = new Date(data.data.date);
          }
          this.onMessage?.(data);
          break;
        case 'fullUpdate':
          // Ensure dates are properly parsed in the full update
          if (Array.isArray(data.data)) {
            data.data = data.data.map((reservation: any) => ({
              ...reservation,
              date: new Date(reservation.date)
            }));
          }
          this.onMessage?.(data);
          break;
        case 'pong':
          // Heartbeat response, do nothing
          break;
        case 'initialData':
          console.log('Received initial data');
          // Ensure dates are properly parsed in the initial data
          if (Array.isArray(data.data)) {
            data.data = data.data.map((reservation: any) => ({
              ...reservation,
              date: new Date(reservation.date)
            }));
          }
          this.onMessage?.(data);
          break;
        default:
          console.warn('Received unknown message type:', data.type);
      }
    } catch (error) {
      console.error('Error parsing WebSocket message:', error);
    }
  };

  private reconnect() {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts++;
      const delay = Math.min(
        this.reconnectInterval * Math.pow(2, this.reconnectAttempts - 1) + Math.random() * 1000, // Add some jitter
        this.maxReconnectDelay
      );
      console.log(`Attempting to reconnect (${this.reconnectAttempts}/${this.maxReconnectAttempts}) in ${delay}ms`);
      this.reconnectTimer = setTimeout(() => {
        this.connect();
      }, delay);
    } else {
      console.log('Max reconnect attempts reached. WebSocket connection failed.');
    }
  }

  private startHeartbeat(): void {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
    }
    this.heartbeatInterval = setInterval(() => {
      this.ping();
    }, 30000); // Send a ping every 30 seconds
  }

  private stopHeartbeat(): void {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
  }

  public send(data: any): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      // Ensure dates are converted to ISO strings before sending
      const processedData = this.processDataForSending(data);
      this.socket.send(JSON.stringify(processedData));
    } else {
      console.error('WebSocket is not open. Message not sent.');
    }
  }

  private processDataForSending(data: any): any {
    if (data && typeof data === 'object') {
      if (data instanceof Date) {
        return data.toISOString();
      }
      if (Array.isArray(data)) {
        return data.map(item => this.processDataForSending(item));
      }
      const processedData: {[key: string]: any} = {};
      for (const key in data) {
        processedData[key] = this.processDataForSending(data[key]);
      }
      return processedData;
    }
    return data;
  }

  public close(): void {
    this.stopHeartbeat();
    if (this.socket) {
      this.socket.close();
    }
    if (this.reconnectTimer) {
      clearTimeout(this.reconnectTimer);
      this.reconnectTimer = null;
    }
  }

  public get readyState(): number {
    return this.socket ? this.socket.readyState : WebSocket.CLOSED;
  }
}

export default WebSocketService.getInstance();