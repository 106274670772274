import React from 'react';
import { Box, Tooltip } from '@mui/material';

interface SyncIndicatorProps {
  status: 'syncing' | 'synced' | 'error';
}

const SyncIndicator: React.FC<SyncIndicatorProps> = ({ status }) => {
  const getColor = () => {
    switch (status) {
      case 'syncing':
        return '#FFA500'; // Orange
      case 'synced':
        return '#4CAF50'; // Green
      case 'error':
        return '#F44336'; // Red
      default:
        return '#9E9E9E'; // Grey
    }
  };

  const getMessage = () => {
    switch (status) {
      case 'syncing':
        return 'Päivitetään';
      case 'synced':
        return 'Ajan tasalla';
      case 'error':
        return 'Päivitys epäonnistui';
      default:
        return 'Tuntematon tila';
    }
  };

  return (
    <Tooltip title={getMessage()} arrow>
      <Box
        sx={{
          width: 12,
          height: 12,
          borderRadius: '50%',
          backgroundColor: getColor(),
          transition: 'background-color 0.3s ease',
        }}
      />
    </Tooltip>
  );
};

export default SyncIndicator;
