import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';

const InstallPWA: React.FC = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState<any>(null);
  const [showIOSInstructions, setShowIOSInstructions] = useState(false);

  const isIOS = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator['standalone'] as boolean);

  useEffect(() => {
    const handler = (e: Event) => {
      e.preventDefault();
      console.log('Can be installed!');
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    const isIOSDevice = isIOS();
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    
    if (isStandalone || isInStandaloneMode()) {
      console.log('Already installed or running in standalone mode');
      setSupportsPWA(false);
    } else if (isIOSDevice && isSafari) {
      console.log('iOS device detected, showing instructions');
      setSupportsPWA(true);
    } else if ('serviceWorker' in navigator && 'BeforeInstallPromptEvent' in window) {
      window.addEventListener('beforeinstallprompt', handler as EventListener);
    }

    return () => window.removeEventListener('beforeinstallprompt', handler as EventListener);
  }, []);

  const onClick = async (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    evt.preventDefault();
    if (promptInstall) {
      promptInstall.prompt();
      const { outcome } = await promptInstall.userChoice;
      console.log(`User response to the install prompt: ${outcome}`);
      if (outcome === 'accepted') {
        console.log('User accepted the install prompt');
        setSupportsPWA(false);
      }
    } else {
      // iOS device
      setShowIOSInstructions(true);
    }
  };

  return (
    <>
      {supportsPWA && (
        <Button
          color="inherit"
          onClick={onClick}
          sx={{ minHeight: '48px', borderColor: 'transparent' }}
        >
          Asenna sovellus
        </Button>
      )}
      <Dialog open={showIOSInstructions} onClose={() => setShowIOSInstructions(false)}>
        <DialogTitle>Asenna sovellus iOS-laitteelle</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            1. Napauta Jaa-kuvaketta selaimen alaosassa.<br/>
            2. Vieritä alas ja napauta "Lisää kotinäyttöön".<br/>
            3. Napauta "Lisää" oikeasta yläkulmasta.<br/>
            4. Sovellus on nyt asennettu kotinäytöllesi.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowIOSInstructions(false)}>Sulje</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InstallPWA;
