import { Box, Button, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import { format, isSameDay } from 'date-fns';
import { fi } from 'date-fns/locale';
import React, { useCallback, useEffect, useState } from 'react';
import { api, Reservation } from '../services/api';
import { offlineStorage } from '../services/offlineStorage';
import WebSocketService from '../services/WebSocketService';
import DemandChangeDialog from './DemandChangeDialog';

interface ReservationManagerProps {
  selectedDate: Date | null;
  reservations: Reservation[];
  onReservationChange: (changedReservation: Reservation | { id: string }, action: 'add' | 'update' | 'delete') => void;
  selectedUser: string | null;
}

const ReservationManager: React.FC<ReservationManagerProps> = ({
  selectedDate,
  reservations,
  onReservationChange,
  selectedUser,
}) => {
  console.log('ReservationManager render:', { selectedDate, reservationsCount: reservations.length, selectedUser });

  const [demandChangeDialogOpen, setDemandChangeDialogOpen] = useState(false);
  const [existingReservation, setExistingReservation] = useState<Reservation | undefined>(undefined);

  const handleWebSocketMessage = useCallback((message: any) => {
    switch (message.type) {
      case 'newReservation':
        onReservationChange(message.data, 'add');
        break;
      case 'updateReservation':
        onReservationChange(message.data, 'update');
        break;
      case 'deleteReservation':
        onReservationChange({ id: message.data }, 'delete');
        break;
      case 'fullUpdate':
        message.data.forEach((reservation: Reservation) => {
          onReservationChange(reservation, 'update');
        });
        break;
    }
  }, [onReservationChange]);

  useEffect(() => {
    WebSocketService.initialize('wss://parkki.liulia.net/ws', handleWebSocketMessage);
    WebSocketService.connect();

    return () => {
      // No need to close the connection here, as it's managed by the singleton
    };
  }, [handleWebSocketMessage]);

  const sendMessage = useCallback((message: any) => {
    WebSocketService.send(message);
  }, []);

  useEffect(() => {
    console.log('useEffect triggered. selectedDate:', selectedDate, 'reservations:', reservations);
    if (selectedDate) {
      const reservation = reservations.find((r) => {
        const rDate = new Date(r.date);
        return isSameDay(rDate, selectedDate);
      });
      console.log('Found reservation:', reservation);
      setExistingReservation(reservation);
    } else {
      setExistingReservation(undefined);
    }
  }, [selectedDate, reservations]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const updatedReservations = await api.getReservations();
        updatedReservations.forEach(reservation => {
          onReservationChange(reservation, 'update');
        });
      } catch (error) {
        console.error('Failed to fetch reservations:', error);
      }
    };

    fetchData();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        fetchData();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [onReservationChange]);

  const addReservation = async () => {
    if (selectedDate && selectedUser) {
      // Ensure the date is in the local timezone
      const localDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
      
      const newReservation: Omit<Reservation, 'id'> = { 
        date: localDate,
        user: selectedUser,
        version: 1,
      };
  
      if (navigator.onLine) {
        try {
          const addedReservation = await api.addReservation(newReservation);
          onReservationChange(addedReservation, 'add');
          sendMessage({ type: 'newReservation', data: addedReservation });
        } catch (error) {
          console.error('Error adding reservation:', error);
          alert('Failed to add reservation. Please try again.');
        }
      } else {
        // For offline mode, still use a temporary ID
        const offlineReservation: Reservation = {
          ...newReservation,
          id: `temp-${Date.now()}`,
        };
        onReservationChange(offlineReservation, 'add');
        offlineStorage.saveReservation(offlineReservation, 'add');
      }
    }
  };
  
  const removeReservation = async () => {
    if (existingReservation && existingReservation.id) {
      console.log('Attempting to remove reservation:', existingReservation);
      onReservationChange({ id: existingReservation.id }, 'delete');
  
      if (navigator.onLine) {
        try {
          await api.deleteReservation(existingReservation.id);
          console.log('Reservation removed successfully:', existingReservation.id);
          setExistingReservation(undefined);
          sendMessage({ type: 'deleteReservation', data: existingReservation.id });
        } catch (error) {
          console.error('Error removing reservation:', error);
          onReservationChange(existingReservation, 'add');
          alert('Failed to remove reservation. Please try again.');
        }
      } else {
        console.log('Offline: Adding delete action to pending actions');
        offlineStorage.saveReservation(existingReservation, 'delete');
        setExistingReservation(undefined);
      }
    }
  };

  const handleDemandChange = async (reason: string) => {
    if (existingReservation && existingReservation.id && selectedUser) {
      try {
        const updatedReservation = await api.demandChange(existingReservation.id, selectedUser, reason);
        console.log('Server response:', updatedReservation);
        onReservationChange(updatedReservation, 'update');
        sendMessage({ type: 'updateReservation', data: updatedReservation });
      } catch (error) {
        console.error('Error demanding change:', error);
        if (axios.isAxiosError(error) && error.response) {
          console.error('Server response:', error.response.data);
        }
        alert('Failed to request change. Please try again later.');
      }
    } else {
      console.error('Invalid reservation or user:', { existingReservation, selectedUser });
    }
    setDemandChangeDialogOpen(false);
  };

  const handleCancelDemand = async () => {
    if (existingReservation && existingReservation.id) {
      try {
        const updatedReservation = await api.cancelDemand(existingReservation.id);
        console.log('Server response:', updatedReservation);
        onReservationChange(updatedReservation, 'update');
        sendMessage({ type: 'updateReservation', data: updatedReservation });
      } catch (error) {
        console.error('Error cancelling demand:', error);
        if (axios.isAxiosError(error) && error.response) {
          console.error('Server response:', error.response.data);
        }
        alert('Failed to cancel demand. Please try again later.');
      }
    }
  };
  
  const handleAcceptChange = async () => {
    if (existingReservation && existingReservation.id && existingReservation.demandChange) {
      const updatedReservation = { 
        ...existingReservation, 
        user: existingReservation.demandChange.user, 
        demandChange: undefined 
      };
      onReservationChange(updatedReservation, 'update');
  
      if (navigator.onLine) {
        try {
          const serverUpdatedReservation = await api.acceptChange(existingReservation.id);
          onReservationChange(serverUpdatedReservation, 'update');
          sendMessage({ type: 'updateReservation', data: serverUpdatedReservation });
        } catch (error) {
          console.error('Error accepting change:', error);
          onReservationChange(existingReservation, 'update');
          alert('Failed to accept change. Please try again.');
        }
      } else {
        offlineStorage.saveReservation(updatedReservation, 'update');
      }
    }
  };
  
  const handleDenyChange = async () => {
    if (existingReservation && existingReservation.id) {
      const updatedReservation = { ...existingReservation, demandChange: undefined };
      onReservationChange(updatedReservation, 'update');
  
      if (navigator.onLine) {
        try {
          const serverUpdatedReservation = await api.denyChange(existingReservation.id);
          onReservationChange(serverUpdatedReservation, 'update');
          sendMessage({ type: 'updateReservation', data: serverUpdatedReservation });
        } catch (error) {
          console.error('Error denying change:', error);
          onReservationChange(existingReservation, 'update');
          alert('Failed to deny change. Please try again.');
        }
      } else {
        offlineStorage.saveReservation(updatedReservation, 'update');
      }
    }
  };

  const userHasDemandedChange = existingReservation?.demandChange?.user === selectedUser;

  const theme = useTheme();

  return (
    <Paper elevation={0} sx={{ p: 1, border: '2px solid #000' }}>
      <Typography variant="body2" gutterBottom>
        {selectedDate 
          ? (
            <>
              {format(selectedDate, "d. MMMM yyyy", { locale: fi })}
              <br />
              <Typography component="span" variant="caption" sx={{ fontWeight: 'bold' }}>
                {format(selectedDate, "EEEE", { locale: fi }).toUpperCase()}
              </Typography>
            </>
          )
          : 'Valitse päivämäärä'}
      </Typography>
      {existingReservation ? (
        <>
          <Typography variant="body2">
            Varaaja: {existingReservation.user}
          </Typography>
          {existingReservation.user === selectedUser ? (
            <>
            <Button
              variant="outlined"
              color="primary"
              onClick={removeReservation}
              disabled={!existingReservation}
              sx={{ mt: 1, width: '100%' }}
            >
              Poista varaus
            </Button>
              {existingReservation.demandChange && (
                <Box mt={1}>
                  <Typography variant="body2">
                    Muutospyyntö: {existingReservation.demandChange.user}
                  </Typography>
                  <Typography variant="body2">
                    Syy: {existingReservation.demandChange.reason}
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleAcceptChange}
                    sx={{ mt: 1, mr: 1, width: 'calc(50% - 4px)' }}
                  >
                    Hyväksy
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleDenyChange}
                    sx={{ mt: 1, width: 'calc(50% - 4px)' }}
                  >
                    Hylkää
                  </Button>
                </Box>
              )}
            </>
          ) : (
            userHasDemandedChange ? (
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancelDemand}
                sx={{ mt: 1, width: '100%' }}
              >
                Peru muutospyyntö
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => setDemandChangeDialogOpen(true)}
                sx={{
                  mt: 1,
                  width: '100%',
                  backgroundColor: theme.palette.error.main,
                  color: theme.palette.error.contrastText,
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: theme.palette.error.dark,
                  },
                }}
              >
                PYYDÄ MUUTOSTA
              </Button>
            )
          )}
        </>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={addReservation}
          disabled={!selectedDate}
          sx={{ mt: 1, width: '100%' }}
        >
          Lisää varaus
        </Button>
      )}
      <DemandChangeDialog
        open={demandChangeDialogOpen}
        onClose={() => setDemandChangeDialogOpen(false)}
        onSubmit={handleDemandChange}
      />
    </Paper>
  );
};

export default React.memo(ReservationManager, (prevProps, nextProps) => {
  return (
    prevProps.selectedDate === nextProps.selectedDate &&
    prevProps.reservations === nextProps.reservations &&
    prevProps.selectedUser === nextProps.selectedUser &&
    prevProps.onReservationChange === nextProps.onReservationChange
  );
});