import { Reservation } from './api';

const STORAGE_KEY = 'parkki_reservations';
const PENDING_ACTIONS_KEY = 'parkki_pending_actions';

function serializeDate(date: Date): string {
  return date.toISOString().split('T')[0];
}

function deserializeDate(dateString: string): Date {
  return new Date(dateString + 'T00:00:00.000Z');
}

export const offlineStorage = {
  saveReservations: (reservations: Reservation[]) => {
    const serializedReservations = reservations.map(r => ({
      ...r,
      date: serializeDate(new Date(r.date))
    }));
    localStorage.setItem(STORAGE_KEY, JSON.stringify(serializedReservations));
  },

  getReservations: (): Reservation[] => {
    const storedData = localStorage.getItem(STORAGE_KEY);
    if (storedData) {
      return JSON.parse(storedData).map((r: any) => ({
        ...r,
        date: deserializeDate(r.date)
      }));
    }
    return [];
  },

  saveReservation: (reservation: Reservation, action: 'add' | 'update' | 'delete') => {
    const reservations = offlineStorage.getReservations();
    let updatedReservations: Reservation[];
  
    switch (action) {
      case 'add':
        updatedReservations = [...reservations, reservation];
        break;
      case 'update':
        updatedReservations = reservations.map(r => 
          r.id === reservation.id && r.version < reservation.version ? reservation : r // Only update if the local version is newer
        );
        break;
      case 'delete':
        updatedReservations = reservations.filter(r => r.id !== reservation.id);
        break;
    }
  
    offlineStorage.saveReservations(updatedReservations);
    offlineStorage.addPendingAction({ type: `${action.toUpperCase()}_RESERVATION`, payload: reservation });
  },

  addPendingAction: (action: { type: string; payload: any }) => {
    const pendingActions = offlineStorage.getPendingActions();
    pendingActions.push(action);
    localStorage.setItem(PENDING_ACTIONS_KEY, JSON.stringify(pendingActions));
  },

  getPendingActions: (): { type: string; payload: any }[] => {
    const storedActions = localStorage.getItem(PENDING_ACTIONS_KEY);
    return storedActions ? JSON.parse(storedActions) : [];
  },

  clearPendingActions: () => {
    localStorage.removeItem(PENDING_ACTIONS_KEY);
  },

  clear: () => {
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem(PENDING_ACTIONS_KEY);
  },

  saveAction(action: { type: string, payload: any }) {
    const pendingActions = this.getPendingActions();
    pendingActions.push(action);
    localStorage.setItem('pendingActions', JSON.stringify(pendingActions));
  }
};